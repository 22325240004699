<template>
  <div class="list-warranty-benefit-policy-page">
    <KTCodePreview v-bind:title="'Danh sách chính sách BHMR'">
      <template v-slot:toolbar>
        <div class="row">
          <div class="ml-3">
            <b-dropdown
              v-if="checkPermission('WARRANTY_BENEFIT_POLICY_CREATE')"
              size="sm"
              right
              split
              variant="primary"
              @click="redirectToCreatePage()"
              class="ml-4"
            >
              <template v-slot:button-content>
                <div class="font-weight-bolder">
                  <span> <i class="flaticon2-add-1"></i>Tạo mới</span>
                </div>
              </template>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <!-- tag-->
        <b-row class="mb-5">
          <b-col md="3">
            <b-form-input
              v-model.trim="params.name"
              type="text"
              placeholder="Tìm kiếm theo tên chính sách bảo hành"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>

          <b-col md="3">
            <b-form-select
              size="sm"
              v-model="params.type"
              :options="warrantyBenefitPolicyType"
              value-field="id"
              text-field="name"
              class="select-style"
            >
              <template v-slot:first>
                <b-form-select-option :value="null"
                  >-- Chọn loại chính sách bảo hành --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <!--end tag-->
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              variant="primary"
              size="sm"
              class="btn font-weight-bold btn-size"
              @click="onFilter"
              :disabled="onLoading"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="listWarrantyBenefitPolicy"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
          @row-clicked="editItem"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:cell(createdAt)="row">
            {{ formatDate(row.item.createdAt, DATE_FORMAT) }}
          </template>
          <template v-slot:cell(updatedByName)="row">
            {{ updatedByName ?? row.item.createdByName }}
          </template>

          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i class="flaticon2-settings"></i>
                </template>
                <b-dropdown-item
                  v-if="checkPermission('WARRANTY_BENEFIT_POLICY_CREATE')"
                  @click="editItem(row.item)"
                >
                  <span style="color: #3f4254; font-size: 10px">
                    <i class="flaticon2-pen icon-size"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="checkPermission('WARRANTY_BENEFIT_POLICY_DELETE')"
                  @click="
                    showDeleteAlert(
                      `${modalDeleteTitle} '${row.item.name}'`,
                      modalDeleteDesc,
                      row.item,
                      confirmDeleteWarrantyBenfitPolicy,
                    )
                  "
                >
                  <span style="color: #3f4254; font-size: 10px">
                    <i
                      class="flaticon2-rubbish-bin-delete-button icon-size text-danger"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark font-weight-bold">
              Tổng:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <BPaginationNavCustom
              :number-of-pages="totalPages || 1"
              :current-page="params.page"
              :total-page="totalPages"
              :linkParams="{}"
              @page-change="fetchData()"
            >
            </BPaginationNavCustom>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { VclTable } from 'vue-content-loading';
import 'vue-cute-timeline/dist/index.css';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { formatDate, DATE_FORMAT, CLIENT_DATE_TIME } from '@/utils/date';
import { showDeleteAlert } from '@/utils/sweet-alert2';
import BPaginationNavCustom from '@/view/base/bootstrap/BPaginationNavCustom.vue';
import { checkPermission } from '@/utils/saveDataToLocal';
import { cmdUrl } from '@/utils/apiUrl';

export default {
  data() {
    return {
      linkParams: {},
      modalDeleteTitle: 'Xoá ',
      modalDeleteDesc: 'Bạn có chắc muốn xoá. Thao tác này không thể hoàn tác!',
      CLIENT_DATE_TIME: CLIENT_DATE_TIME,
      DATE_FORMAT: DATE_FORMAT,
      onLoading: false,
      listWarrantyBenefitPolicy: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '4%',
            textAlign: 'center',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên chính sách',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'typeName',
          label: 'Loại',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'updatedByName',
          label: 'Người cập nhật',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-left',
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          thStyle: { width: '10%' },
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      totalPages: 1,
      totalItems: 0,
      params: {
        page: 1,
        limit: 10,
        name: null,
        type: null,
      },
      warrantyBenefitPolicyType: [],
    };
  },
  methods: {
    showDeleteAlert,
    formatDate,
    checkPermission,
    async confirmDeleteWarrantyBenfitPolicy(item) {
      const endPoint = cmdUrl.warrantyBenefitPolicy.byId.replace(
        '{id}',
        item.id,
      );
      ApiService.delete(endPoint).then(({ data }) => {
        makeToastSuccess(data?.message);
        this.listWarrantyBenefitPolicy = this.listWarrantyBenefitPolicy.filter(
          (policy) => policy.id !== item.id,
        );
      });
    },
    redirectToCreatePage() {
      this.navigateToUpsertWarrantyBenfitPolicy({ action: 'create' });
    },
    refreshPage() {
      this.fetchData();
    },
    navigateToUpsertWarrantyBenfitPolicy(query = {}) {
      this.$router.push({
        name: 'upsert-warranty-benefit-policy',
        query,
      });
    },
    editItem(item) {
      this.navigateToUpsertWarrantyBenfitPolicy({
        action: 'update',
        id: item.id,
      });
    },
    onFilter() {
      this.fetchData();
    },
    setParamFilter() {
      return {
        ...this.params,
        name: this.params.name,
      };
    },
    fetchWarrantyBenefitPolicyType() {
      ApiService.get(cmdUrl.warrantyBenefitPolicy.listType).then(({ data }) => {
        this.warrantyBenefitPolicyType = data.data;
      });
    },
    fetchData: async function (page) {
      try {
        if (this.onLoading) return;
        this.onLoading = true;
        this.params.page = page ?? this.$route.query.page ?? 1;
        const params = this.setParamFilter();
        const {
          data: { data },
        } = await ApiService.query(cmdUrl.warrantyBenefitPolicy.root, {
          params,
        });
        this.listWarrantyBenefitPolicy = data.result;
        this.totalItems = data.totalItems;
        this.totalPages = data.totalPages;
      } catch (error) {
        const message =
          error.response?.data?.message ?? error.message ?? 'Network error';
        makeToastFaile(message);
      } finally {
        this.onLoading = false;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Ngành hàng', route: 'warranty-benefit-policy' },
      { title: 'Danh sác chính sách bảo hành' },
    ]);
  },
  created() {
    this.fetchWarrantyBenefitPolicyType();
    this.fetchData();
  },
  components: {
    KTCodePreview,
    VclTable,
    BPaginationNavCustom,
  },
};
</script>

<style scoped>
.btn-size {
  width: 90px;
}

.icon-size {
  font-size: 1rem;
}

.nav .nav-link {
  color: #3f4254;
  font-weight: 800;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.form-group {
  margin-bottom: 1em;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.icon:hover {
  background-color: #90c6fc;
}
</style>
<style lang="scss" scoped>
.list-warranty-benefit-policy-page {
  .dropdown-item-text {
    font-size: 12px;
    color: #3f4254;
  }
}
</style>
